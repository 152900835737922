<!-- 培训新生注册编辑 -->
<template>
    <div class="EditTrainNewborn">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">培训新生注册编辑</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form" :rules="rules" id="form-1" class="" inline label-width="150px">
            <el-form-item label="登记单号">
                <el-input v-model="form.number" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="经办人员" prop="handle">
                <el-select v-model="form.handle" filterable placeholder="请输入并选择经办人员" :clearable="true" disabled>
                    <el-option v-for="item in form.handleOpt" :key="item.id" :label="item.fullname"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="报名日期">
                <el-date-picker
                  v-model="form.enrollDate"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="学生姓名" prop="name">
                <el-select v-model="form.name" filterable remote placeholder="请输入并选择学员姓名" :clearable="true"
                    :remote-method="students" @change="studentChange">
                    <el-option v-for="item in form.studentOpt" :key="item.id" :label="item.username"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="联系电话" prop="telephone">
                <el-input v-model="form.telephone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.condition" placeholder="请选择状态" :clearable="true">
                    <el-option
                      v-for="item in form.stateOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="选择班级" prop="optClass">
                <el-select v-model="form.optClass" filterable  placeholder="请输入并选择班级" :clearable="true"
                @change="classChange">
                    <el-option v-for="item in form.optClassOpt" :key="item.id" :label="item.number"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="授课老师" >
                <el-input v-model="form.teacher" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="班级名称">
                <el-input v-model="form.className" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="开班日期">
                <el-date-picker
                value-format="yyyy-MM-dd"
                  v-model="form.offerDate"
                  type="date"
                  placeholder="" disabled>
                </el-date-picker>
            </el-form-item>
            <el-form-item label="结业日期">
                <el-date-picker
                value-format="yyyy-MM-dd"
                  v-model="form.graduation"
                  type="date"
                  placeholder="" disabled>
                </el-date-picker>
            </el-form-item>
            <el-form-item label="标准授课次数">
                <el-input v-model="form.frequency" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="上课地点">
                <el-input v-model="form.locale" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="报名上课次数">
                <el-input v-model="form.enroll" placeholder="请输入报名上课次数"></el-input>
            </el-form-item>
            <el-form-item label="课时安排说明">
                <el-input v-model="form.arrange" placeholder="请输入课时安排说明" disabled></el-input>
            </el-form-item>
            <el-form-item label="学杂费">
                <el-input v-model="form.standard" placeholder="请输入学杂费"></el-input>
            </el-form-item>
            <el-form-item label="其中学费">
                <el-input v-model="form.tuition" placeholder="请输入其中学费"></el-input>
            </el-form-item>
            <el-form-item label="杂费">
                <el-input v-model="form.incidental_1" placeholder="请输入杂费"></el-input>
            </el-form-item>
            
            <el-form-item label="交费日期">
                <el-date-picker
                value-format="yyyy-MM-dd"
                  v-model="form.payDate"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="优惠前应收学杂费">
                <el-input v-model="form.B_discount" placeholder="请输入优惠前应收学杂费"></el-input>
            </el-form-item>
            <el-form-item label="优惠率">
                <el-input v-model="form.rate" placeholder="请输入优惠率"></el-input>
            </el-form-item>
            <el-form-item label="让利金额">
                <el-input v-model="form.yield" placeholder="请输入让利金额"></el-input>
            </el-form-item>
            <el-form-item label="优惠后应收学杂费">
                <el-input v-model="form.A_discount" placeholder="请输入优惠后应收学杂费"></el-input>
            </el-form-item>
            <el-form-item label="实收金额">
                <el-input v-model="form.actual" placeholder="请输入实收金额"></el-input>
            </el-form-item>
            <el-form-item label="定金">
                <el-input v-model="form.deposit" placeholder="请输入定金"></el-input>
            </el-form-item>
            <el-form-item label="转班带入学费">
                <el-input v-model="form.bring" placeholder="请输入转班带入学费"></el-input>
            </el-form-item>
            <el-form-item label="报退费金额">
                <el-input v-model="form.refund" placeholder="请输入报退费金额"></el-input>
            </el-form-item>
            <el-form-item label="退学日期">
                <el-date-picker
                value-format="yyyy-MM-dd"
                  v-model="form.dropOut"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="支付方式">
                <el-select v-model="form.payment" placeholder="请选择支付方式" :clearable="true">
                    <el-option
                      v-for="item in form.paymentOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="报名备注">
                <el-input v-model="form.remarks" type="textarea" class="textArea-width" placeholder="请输入报名备注"></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               form:{
                 number:"",//登记单号
                 handle:"",//经办人员
                 handleOpt:[],//经办人员选项
                 enrollDate:"",//报名日期
                 name:"",//学生姓名id
                 studentOpt: [], //学员选项
                 telephone:"",//联系电话
                 condition:'',//状态
                 stateOpt:[
                    {label: "开班入学", value: 1},
                    {label: "插班入学", value: 2},
                    {label: "转班入学", value: 3},
                    {label: "结业1", value: 4},
                    {label: "结业2", value: 5},
                    {label: "结业", value: 6},
                    {label: "退学", value: 7}
                 ],//状态选项
                 optClass:"",//选择班级
                 optClassOpt:[],//选择班级选项
                 teacher:"",//授课老师
                 teacherOpt:[],//授课老师选项
                 className:"",//班级名称
                 offerDate:"",//开班日期
                 graduation:"",//结业日期
                 frequency:"",//标准授课次数
                 locale:"",//上课地点
                 standard:"",//标准学杂费
                 tuition:"",//其中学费
                 arrange:"",//课时安排说明
                 incidental_1:"",//杂费
                 enroll:"",//报名上课次数
                 payDate:"",//交费日期
                 B_discount:"",//优惠前应收学杂费
                 rate:"",//优惠率
                 yield:"",//让利金额
                 A_discount:"",//优惠后应收学杂费
                 actual:"",//实收金额
                 incidental_2:"",//杂费
                 deposit:"",//定金
                 bring:"",//转班带入学费
                 refund:"",//报退费金额
                 dropOut:"",//退学日期
                 payment:"",//支付方式
                 paymentOpt:[
                     {label: "现金", value: 1},
                     {label: "银行转账", value: 2},
                     {label: "支票", value: 3},
                     {label: "信用卡", value: 4},
                     {label: "借记卡", value: 5},
                     {label: "移动支付", value: 6},
                 ],//支付方式选项
                 remarks:""//报名备注
               },
               rules:{
                   handle:[
                       { required: true, message: '经办人员不能为空', trigger: 'blur' },
                   ],
                   name:[
                       { required: true, message: '学生姓名不能为空', trigger: 'blur' },
                   ],
                   optClass:[
                       { required: true, message: '请选择班级', trigger: 'change' },
                   ],
                   telephone:[
                       { required: true, message: '联系电话不能为空', trigger: 'blur' },
                   ],
                   teacher:[
                       { required: true, message: '授课老师不能为空', trigger: 'blur' },
                   ]
               } ,
               bool:false
            }
        },
        created() {
            // 经办人员数据
            this.$request({
                url:'/api/humanresources/list',
                method:'POST',
                data:{
                    page:1,
                    limit:100
                }
            }).then(res=>{
                // console.log(res)
                if(res.code==1){
                    this.form.handleOpt=res.data.list
                    this.form.teacherOpt=res.data.list
                }
            })
            
            // 选择班级数据
            this.$request({
                url:'/api/class_/list',
                method:'POST',
                data:{
                    page:1,
                    limit:100
                }
            }).then(res=>{
                // console.log(res)
                if(res.code==1){
                    this.form.optClassOpt=res.data.list
                }
            })
            this.students()
            // 初次渲染
            this.$request({
                url:'/api/studenttrain/detail',
                method:'POST',
                data:{
                    id:this.$route.query.id
                }
            }).then(res=>{
                console.log(res,'初次渲染')
                if(res.code==1){
                    let data = res.data
                    let form = this.form
                    form.number=data.number//登记单号
                    form.handle=data.handle_id//经办人员
                    form.enrollDate=data.registration//报名日期
                    form.name=data.student_id//学生姓名id
                    form.telephone=data.mobile//联系电话
                    form.condition=data.train_status//状态
                    form.optClass=data.class_id//选择班级id
                    form.teacher=data.resources//授课老师
                    form.className=data.class_name//班级名称
                    form.offerDate=data.start_time//开班日期
                    form.graduation=data.end_time//结业日期
                    form.frequency=data.bz_lecture_number//标准授课次数
                    form.locale=data.classroom//上课地点
                    form.standard=data.tuition_incidentals//学杂费
                    form.tuition=data.tuition//其中学费
                    form.arrange=data.class_arrangement//课时安排说明
                    form.incidental_1=data.incidentals//杂费
                    form.enroll=data.bm_lecture_number//报名上课次数
                    form.payDate=data.peyment_date//交费日期
                    form.B_discount=data.before_discount//优惠前应收学杂费
                    form.rate=data.discount_ratio//优惠率
                    form.yield=data.discount_price//让利金额
                    form.A_discount=data.after_discount//优惠后应收学杂费
                    form.actual=data.amount_price//实收金额
                    form.deposit=data.deposit//定金
                    form.bring=data.transfer_tuition//转班带入学费
                    form.refund=data.refund_amount//报退费金额
                    form.dropOut=data.drop_out//退学日期
                    form.payment=data.pay_type//支付方式
                    form.remarks=data.remark//报名备注
                }
            })
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) { //提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        console.log(this.form)
                        if(this.bool){
                            return
                        }
                        this.bool=true
                        let form = this.form
                        this.$request({
                            url:'/api/studenttrain/edit',
                            method:'POST',
                            data:{
                                id:this.$route.query.id,
                                number:form.number,//登记单号
                                resources:form.teacher,//授课老师名称
                                registration:form.enrollDate,//报名日期
                                student_id:form.name,//学生ID
                                mobile:form.telephone,//联系方式
                                train_status:form.condition,//培训状态
                                class_id:form.optClass,//班级列表id
                                start_time:form.offerDate,//开班时间
                                end_time:form.graduation,//结业时间
                                bz_lecture_number:form.frequency,//标准授课次数
                                bm_lecture_number:form.enroll,//报名授课次数
                                peyment_date:form.payDate,//交费时间
                                before_discount:form.B_discount,//优惠前学杂费
                                after_discount:form.A_discount,//优惠后学杂费
                                discount_ratio:form.rate,//优惠率
                                discount_price:form.yield,//让利金额
                                actual_amount:form.actual,//实收金额
                                tuition_incidentals:form.standard,//学杂费
                                tuition:form.tuition,//其中学费
                                incidentals:form.incidental_1,//其中杂费
                                deposit:form.deposit,//定金
                                transfer_tuition:form.bring,//转班带入学费
                                remark:form.remarks,//报名备注
                                refund_amount:form.refund,//退费金额
                                drop_out:form.dropOut,//退学日期
                                pay_type:form.payment,//支付方式
                                classroom:form.className,//班级名称
                                handle_id:form.handle,//经办人员ID
                            }
                        }).then(res=>{
                            if(res.code==1){
                                this.$message({
                                    message: '编辑列表成功',
                                    type: 'success'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            }else{
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            }
                        }).catch(()=>{
                            this.bool=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消
                this.goBack()
                this.$refs[form].resetFields();
            },
            //学生数据
            students(e) {
                // console.log(e);
                this.$request({
                    url: "/api/student/list",
                    method: "POST",
                    data: {
                        page: 1,
                        limit: 100,
                        key: e
                    }
                }).then(res => {
                    // console.log("学生数据", res.data.list)
                    this.form.studentOpt = res.data.list
                })
            },
            studentChange(e){//学生数据
                // console.log(e)
                let result = this.form.studentOpt.find(item => item.id == e)
                console.log(result)
                
                
                this.form.studentName=result.username
                this.form.telephone=result.mobile1
                // console.log(result.id,result.idcard)
            },
            
            // 选择班级 改变事件渲染数据
            classChange(e){
                // console.log(e)
                let result = this.form.optClassOpt.find(item => item.id == e)
                console.log(result)
                this.form.teacher=result.resources_id//授课老师
                this.form.className=result.class_name//班级名称
                this.form.offerDate=result.start_time//开班日期
                this.form.graduation=result.end_time//结业日期
                this.form.frequency=result.lecture_number//标准授课次数
                this.form.locale=result.classroom//上课地点
                this.form.standard=result.tuition_incidentals//标准学杂费
                this.form.tuition=result.tuition//其中学费
                this.form.arrange=result.class_arrangement//课时安排说明
                this.form.incidental_1=result.incidentals//杂费
                this.form.enroll=result.lecture_number//报名上课次数
            }
        }
    }
</script>

<style scoped="scoped">
    .EditTrainNewborn{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    
    .el-select {
        width: 300px;
    }
    
    ::v-deep .el-input.is-disabled .el-input__inner {
        background-color: #FFFFFF;
        color: #606266;
    }
    
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
